import axios from "axios";

const InitialState = {
  diagramMatTech: null,
  diagramPerson: null,
  diagramOrganization: null,
  diagramDemo: null,
  diagramInformation: null,
  diagramRequestProgram: null,
  diagramRequestProgramByYear: null,
  diagramProgram: null,
  diagramRoom: null
};


const DASHBOARD_TYPE = "DASHBOARD_TYPE";


function diagramMatTechGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/mat-tech", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramPersonGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/person", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramOrganizationGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/organization", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramDemoGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/demo", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramInformationGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/information", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramRequestProgramGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/request-program", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramRequestProgramByYearGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/request-program-by-year", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramProgramGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/program", {
    headers: {'Accept': 'application/json'},
  });
}

function diagramRoomGetRequest() {
  return axios(process.env.REACT_APP_OPEN_API_URL + "/work/open-api/dashboard/diagram/room", {
    headers: {'Accept': 'application/json'},
  });
}


function diagramMatTechReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramMatTech: digram
      }
    }
  }
}

function diagramPersonReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramPerson: digram
      }
    }
  }
}

function diagramOrganizationReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramOrganization: digram
      }
    }
  }
}

function diagramDemoReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramDemo: digram
      }
    }
  }
}

function diagramInformationReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramInformation: digram
      }
    }
  }
}

function diagramRequestProgramReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramRequestProgram: digram
      }
    }
  }
}

function diagramRequestProgramByYearReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramRequestProgramByYear: digram
      }
    }
  }
}


function diagramProgramReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramProgram: digram
      }
    }
  }
}

function diagramRoomReceive(digram) {
  return {
    type: DASHBOARD_TYPE,
    reduce: (state) => {
      return {
        ...state,
        diagramRoom: digram
      }
    }
  }
}


export function diagramMatTechQuery() {
  return (dispatch) => {
    return diagramMatTechGetRequest()
      .then(response => {
        dispatch(diagramMatTechReceive(response.data))
      }, err => {
        dispatch(diagramMatTechReceive(null))
      })
  }
}

export function diagramPersonQuery() {
  return (dispatch) => {
    return diagramPersonGetRequest()
      .then(response => {
        dispatch(diagramPersonReceive(response.data))
      }, err => {
        dispatch(diagramPersonReceive(null))
      })
  }
}

export function diagramOrganizationQuery() {
  return (dispatch) => {
    return diagramOrganizationGetRequest()
      .then(response => {
        dispatch(diagramOrganizationReceive(response.data))
      }, err => {
        dispatch(diagramOrganizationReceive(null))
      })
  }
}

export function diagramDemoQuery() {
  return (dispatch) => {
    return diagramDemoGetRequest()
      .then(response => {
        dispatch(diagramDemoReceive(response.data))
      }, err => {
        dispatch(diagramDemoReceive(null))
      })
  }
}

export function diagramInformationQuery() {
  return (dispatch) => {
    return diagramInformationGetRequest()
      .then(response => {
        dispatch(diagramInformationReceive(response.data))
      }, err => {
        dispatch(diagramInformationReceive(null))
      })
  }
}

export function diagramRequestProgramQuery() {
  return (dispatch) => {
    return diagramRequestProgramGetRequest()
      .then(response => {
        dispatch(diagramRequestProgramReceive(response.data))
      }, err => {
        dispatch(diagramRequestProgramReceive(null))
      })
  }
}

export function diagramRequestProgramByYearQuery() {
  return (dispatch) => {
    return diagramRequestProgramByYearGetRequest()
      .then(response => {
        dispatch(diagramRequestProgramByYearReceive(response.data))
      }, err => {
        dispatch(diagramRequestProgramByYearReceive(null))
      })
  }
}

export function diagramProgramQuery() {
  return (dispatch) => {
    return diagramProgramGetRequest()
      .then(response => {
        dispatch(diagramProgramReceive(response.data))
      }, err => {
        dispatch(diagramProgramReceive(null))
      })
  }
}

export function diagramRoomQuery() {
  return (dispatch) => {
    return diagramRoomGetRequest()
      .then(response => {
        dispatch(diagramRoomReceive(response.data))
      }, err => {
        dispatch(diagramRoomReceive(null))
      })
  }
}

export function reducer(state = InitialState, action) {
  if (action.type === DASHBOARD_TYPE) {
    return action.reduce(state)
  }
  return state;
}
