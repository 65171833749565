import React from 'react';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

export default function CenterTemplate(total) {
    return function (pieChart) {
        return (<svg>
              <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 6} fill="#eee"/>
              <text textAnchor="middle" x="100" y="100" style={{fontSize: 18, fill: '#494949'}}>
                  <tspan x="100">Всего</tspan>
                  <tspan x="100" dy="20px" style={{fontWeight: 600}}> {total} </tspan>
              </text>
          </svg>
        );
    }
}
