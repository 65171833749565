import React from 'react';
import 'react-app-polyfill/ie11';
import {withStyles} from '@material-ui/styles';
import {Typography} from "@material-ui/core";
import CenterTemplate from './CenterTemplate.js';
import Chart, {
  ArgumentAxis,
  Label,
  Legend,
  Series,
  Connector as ChartConnector,
  Tooltip,
  CommonSeriesSettings,
  SeriesTemplate
} from 'devextreme-react/chart';
import PieChart, {
  Connector as PieChartConnector
} from 'devextreme-react/pie-chart';
import {
  PolarChart,
  CommonSeriesSettings as PolarChartCommonSeriesSettings,
  Tooltip as PolarChartTooltip,
  ArgumentAxis as PolarChartArgumentAxis,
  Label as PolarChartLabel,
  Point as PolarChartPoint,
  Legend as PolarChartLegend,
  SeriesTemplate as PolarChartSeriesTemplate
} from 'devextreme-react/polar-chart';
import {connect} from 'react-redux';
import {Link} from '@material-ui/core';
import "./styles.css";
import {
  diagramRoomQuery,
  diagramPersonQuery,
  diagramOrganizationQuery,
  diagramRequestProgramByYearQuery,
  diagramRequestProgramQuery,
  diagramProgramQuery
} from "./reducer";

const dashboardStyles = {
  root: {
    width: '100%',
    textAlign: "center"
  },
  header: {
    marginTop: "32px",
    marginBottom: "16px",
    fontWeight: 600
  },
  chart: {
    height: "260px"
  },
  typo: {
    fontSize: 16,
    lineHeight: "24px"
  },
  programTitle: {
    fontSize: 16,
    fontWeight: 600,
    "text-decoration": "none",
    color: "#000000"
  },
  programCount: {
    fontSize: 24,
    fontWeight: 600,
    color: '#D0101B'
  },
  infCount: {
    fontSize: 24,
    fontWeight: 600,
    color: '#ffffff',
    backgroundColor: '#97c95c',
    width: "100px",
    margin: "16px auto",
    paddingTop: "16px",
    paddingBottom: "16px"
  }
};

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

class Dashboard extends React.PureComponent {

  componentDidMount() {
    //this.props.diagramRoomQuery();
    //this.props.diagramPersonQuery();
    //this.props.diagramOrganizationQuery();
    this.props.diagramProgramQuery();
    this.props.diagramRequestProgramByYearQuery();
    this.props.diagramRequestProgramQuery();
  }

  customizeLabel = (e) => {
    return `${e.argumentText}\n${e.valueText}`;
  };

  render() {
    const {
      classes,
  //  diagramRoom,
  //  diagramPerson,
  //  diagramOrganization,
      diagramProgram,
      diagramRequestProgramByYear,
      diagramRequestProgram
    } = this.props;
    return (
      <div id="dashboard_container">
        <Typography variant="h6" className={classes.header}>
          <Link href={'/app'}>
            Цифровая платформа Центра опережающей профессиональной подготовки Тюменской области
          </Link>
        </Typography>
        <div className="dashboard-flow">
          {diagramProgram && <div className="card">
            <div className="cardBody">
              <PolarChart useSpiderWeb={true}
                          className={classes.chart}
                          dataSource={diagramProgram}>
                <PolarChartArgumentAxis>
                  <PolarChartLabel visible={true} wordWrap="normal" font={{size: 9}}/>
                </PolarChartArgumentAxis>
                <PolarChartCommonSeriesSettings argumentField="category" type="line" valueField="count">
                  <PolarChartPoint size={8}/>
                </PolarChartCommonSeriesSettings>
                <PolarChartSeriesTemplate nameField="targetGroup"/>
                <PolarChartLegend visible={true} verticalAlignment="top" horizontalAlignment="right"/>
                <PolarChartTooltip enabled={true}/>
              </PolarChart>
            </div>
            <a
              href="/work/app/manage/resources/programs/my-programs"
              target="_blank"
              className="cardHeader">
              Опубликованные программы
            </a>
          </div>}
          {diagramRequestProgram && <div className="card">
            <div className="cardBody">
              <Chart resolveLabelOverlapping="stack"
                     dataSource={diagramRequestProgram}
                     className={classes.chart}>
                <ArgumentAxis tickInterval={10}>
                  <Label visible={true} format="decimal"/>
                </ArgumentAxis>
                <CommonSeriesSettings argumentField="date" type="stackedBar" valueField="count">
                  <Label visible={true}>
                    <ChartConnector visible={true}/>
                  </Label>
                </CommonSeriesSettings>
                <SeriesTemplate nameField="region"/>
                <Legend visible={true} verticalAlignment="bottom" horizontalAlignment="center"/>
              </Chart>
            </div>
            <a
              href="/work/app/study/request"
              target="_blank"
              className="cardHeader">
              Заявки на обучение за последние 3 месяца
            </a>
          </div>}
          {diagramRequestProgramByYear && <div className="card">
            <div className="cardBody">
              <RequestProgramByYear data={diagramRequestProgramByYear}/>
            </div>
            <a
              href="/work/app/study/request"
              target="_blank"
              className="cardHeader">
              Заявки на обучение (по годам)
            </a>
          </div>}
          {false /*&&  diagramRoom && <div className="card">
            <div className="cardBody">
              <Chart className={classes.chart}
                     resolveLabelOverlapping="none"
                     dataSource={diagramRoom}>
                <ArgumentAxis>
                  <Label overlappingBehavior="stagger"/>
                </ArgumentAxis>
                <Series type="bar">
                  <Label visible={true}>
                    <ChartConnector visible={true}/>
                  </Label>
                </Series>
                <Tooltip enabled={true}/>
                <Legend visible={false}/>
              </Chart>
            </div>
            <a
              href="/work/app/rooms"
              target="_blank"
              className="cardHeader">
              Помещения и площадки
            </a>
          </div>*/}
          {false /*&& diagramPerson && diagramPerson.values && <div className="card">
            <div className="cardBody">

              <PieChart className={classes.chart}
                        dataSource={diagramPerson.values}
                        palette="Bright">
                <Series
                  argumentField="arg"
                  valueField="val">
                  <Label visible={true}>
                    <PieChartConnector visible={true} width={1}/>
                  </Label>
                </Series>
              </PieChart>
            </div>
            <a
              href="/work/app/person"
              target="_blank"
              className="cardHeader">
              Кадровые ресурсы
            </a>
          </div>*/ }
          {false  /*&&  diagramOrganization && diagramOrganization.values && <div className="card">
            <div className="cardBody">

              <PieChart className={classes.chart}
                        dataSource={diagramOrganization.values}
                        resolveLabelOverlapping="shift"
                        sizeGroup="piesGroup"
                        innerRadius={0.65}
                        centerRender={(!isIE && !isEdge) && CenterTemplate(diagramOrganization.all)}
                        type="doughnut">
                <Series
                  argumentField="arg"
                  valueField="val">
                  <Label visible={true}
                         format="fixedPoint"
                         customizeText={this.customizeLabel}
                         backgroundColor="none">
                    <PieChartConnector visible={true}/>
                  </Label>
                </Series>
                <Legend visible={false}/>
              </PieChart>
            </div>
            <a
              href="/work/app/manage/resources/org"
              target="_blank"
              className="cardHeader">
              Организации
            </a>
          </div>*/}
        </div>
      </div>
    );
  }
}

function RequestProgramByYear({data}) {
  let years = []
  let rows = [];
  if (data) {
    for (let val of data) {
      if (val.year && val.type) {
        let foundYear = false;
        for (let year of years) {
          if (val.year === year) {
            foundYear = true;
            break;
          }
        }
        if (!foundYear) {
          years.push(val.year)
        }
        let targetRow = null;
        for (let row of rows) {
          if (val.type === 2) {
            if (row.type === 2) {
              targetRow = row;
            }
          } else if (row.type === 1 && val.name === row.name) {
            targetRow = row;
          }
        }
        if (!targetRow) {
          targetRow = {
            name: val.name,
            type: val.type
          }
          rows.push(targetRow);
        }
        targetRow['' + val.year] = val.count || 0;
      }
    }
  }
  return (<table className="request-program-by-year">
    <thead>
    <tr>
      <td className="request-program-by-year__name">Реализуется партнёром</td>
      {years.map(year => (<td className="request-program-by-year__year">{year}</td>))}
    </tr>
    </thead>
    <tbody>
    {rows.map(r => (<tr>
      <td className="request-program-by-year__name">{r.type === 1 ? r.name : (<i>Остальные</i>)}</td>
      {years.map(year => (<td className="request-program-by-year__year">{r['' + year] || 0}</td>))}
    </tr>))}
    </tbody>
  </table>)
}

const mapStateToProps = state => ({
  //diagramRoom: state.dashboardView.diagramRoom,
  //diagramPerson: state.dashboardView.diagramPerson,
  //diagramOrganization: state.dashboardView.diagramOrganization,
  diagramRequestProgramByYear: state.dashboardView.diagramRequestProgramByYear,
  diagramProgram: state.dashboardView.diagramProgram,
  diagramRequestProgram: state.dashboardView.diagramRequestProgram
});

const mapDispatchToProps = dispatch => ({
  //diagramRoomQuery: () => dispatch(diagramRoomQuery()),
  //diagramPersonQuery: () => dispatch(diagramPersonQuery()),
  //diagramOrganizationQuery: () => dispatch(diagramOrganizationQuery()),
  diagramRequestProgramByYearQuery: () => dispatch(diagramRequestProgramByYearQuery()),
  diagramProgramQuery: () => dispatch(diagramProgramQuery()),
  diagramRequestProgramQuery: () => dispatch(diagramRequestProgramQuery())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(dashboardStyles)(Dashboard));
