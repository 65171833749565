import "@fortawesome/fontawesome-free/css/all.min.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";

import React from "react";
import Dashboard from "./Dashboard";
import AppToolbar from "./AppToolbar";

class App extends React.Component {

  render() {
    return (
      <div >
        <AppToolbar />
        <Dashboard/>
      </div>);
  }
}

export default App;
