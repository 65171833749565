import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {ReactComponent as RedLogo} from "./images/logo_big_svg.svg";
import {ReactComponent as FlagImage} from "./images/Flag_of_Tyumen_Oblast.svg";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingTop: 59
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'white',
        position: 'fixed',
        "z-index": 1201,
        marginTop: 0,
        marginBottom: 0,
        top: 0,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        paddingRight: 0,
    },
    toolbarLeft: {
        display: 'flex',
        alignItems: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#5A6E78',
    },
    title: {
        flexGrow: 1,
    },
    date: {
        marginLeft: '30px',
        color: '#596E79',
    },
    time: {
        margin: '0 5px 0 20px',
        color: '#596E79'
    },
    timeText: {
        color: '#596E79',
    },
    arrowOrg: {
        color: '#ffffff',
        display: 'inline-block',
    },
    flagImg: {
      height: 36,
      width: 55,
      marginLeft: '8px'
    },
    flagText:{
      width: "120px",
      fontSize: "14px",
      "line-height": "22px",
      marginLeft: '8px',
      color: "#3f51b5",
      "font-weight": 600,
      "text-align": "left",
      "box-sizing": "border-box"
    }
}));

export default function ButtonAppBar() {
    const {
        root, appBar, toolbar, toolbarLeft, flagImg, flagText
    } = useStyles();

    return (
        <>
            <div className={root}>
                <AppBar className={appBar} position="static">
                    <Toolbar className={toolbar}>
                        <div className={toolbarLeft}>
                            <RedLogo/>
                            <FlagImage className={flagImg}/>
                            <div className={flagText}>ЦИФРОВАЯ ПЛАТФОРМА</div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        </>
    );
}
