import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {applyMiddleware, createStore, combineReducers} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";

import {reducer as dashboardView} from "./Dashboard/reducer";
import {composeWithDevTools} from "redux-devtools-extension";


const rootReducer = combineReducers({
  dashboardView
});

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunk)
    : composeWithDevTools(applyMiddleware(thunk));

const store = createStore(rootReducer, devTools);

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    body1: {
      fontSize: 12
    }
  }
});


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App/>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
